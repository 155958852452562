import React from 'react';
import { PageComponent } from './components/PageComponent';
import './App.css';

function App() {
  return (
    <PageComponent/>
  );
}

export default App;
